var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center d-flex align-center justify-center",staticStyle:{"max-width":"300px"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',[(_vm.isEmployee == 1 && _vm.viewStoricoBase == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/messages"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/storico-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Storico")])]}}],null,false,2177089316)},[_c('span',[_vm._v("Storico")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.viewMsgBase == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/messaggioBase"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/messaggio-base-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Messaggio Base")])]}}],null,false,922199339)},[_c('span',[_vm._v("Messaggio Base")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.viewMsgRisorse == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/filterResources"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/messaggio-risorse-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Messaggi Utenti")])]}}],null,false,1970347155)},[_c('span',[_vm._v("Messaggi Utenti")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.viewMsgRefs == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/initCompanyMessaggiReferenti"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/messaggio-referenti-alertmsg.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Messaggi Referenti")])]}}],null,false,2845348659)},[_c('span',[_vm._v("Messaggi Referenti")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }